<template>
    <painel titulo="SSE - Atendimentos" icone="pi pi-key" v-if="mostrarListagem">
        <Panel header="Filtros" :toggleable="true" class="filtro">
            <div class="formgrid grid p-fluid mt-2">
                <div class="field col-12 md:col-3 lg:col-2">
                    <label>Data Atendimento (DE)</label>
                    <Calendar v-model="dataAtendimentoDe" :monthNavigator="true" :yearNavigator="true" :yearRange="yearRange" :manualInput="false" />
                </div>
                <div class="field col-12 md:col-3 lg:col-2">
                    <label>Data Atendimento (ATÉ)</label>
                    <Calendar v-model="dataAtendimentoAte" :monthNavigator="true" :yearNavigator="true" :yearRange="yearRange" :manualInput="false" />
                </div>
                <div class="field col-4">
                    <label>Status</label>
                    <div class="p-inputgroup">
                        <Dropdown v-model="status" :options="statusLista" optionLabel="statusDescr" :filter="true" optionValue="status" />
                    </div>
                </div>
            </div>
            <Button type="button" icon="pi pi-search" label="Pesquisar" class="p-button-info" @click="obterAtendimentos()" />
        </Panel>
        <tabela
            :data="atendimentos"
            stateKey="dt-atendimentos"
            dataKey="atendimentoId"
            :globalFilterFields="['diagnosticoId', 'dataAtendimento', 'statusIntegracaoDescricao']"
        >
            <template #conteudo>
                <Column headerStyle="width: 2em">
                    <template #body="slotProps">
                        <btn-detalhar :atendimentoId="slotProps.data.atendimentoId"></btn-detalhar>
                    </template>
                </Column>
                <Column field="diagnosticoId" header="Cód. Atendimento" :sortable="true" headerStyle="width: 5em">
                    <template #body="slotProps">
                        {{ slotProps.data.diagnosticoId }}
                    </template>
                </Column>
                <Column field="dataAtendimento" header="Data Atendimento" :sortable="true" headerStyle="width: 3em">
                    <template #body="slotProps">
                        {{ $dateFormat(slotProps.data.dataAtendimento, 'DD/MM/YYYY') }}
                    </template>
                </Column>
                <Column field="statusIntegracaoDescricao" header="Status Integrção" :sortable="true" headerStyle="width: 4em">
                    <template #body="slotProps">
                        <status :status="slotProps.data.statusIntegracaoDescricao"></status>
                    </template>
                </Column>
            </template>
        </tabela>
    </painel>
    <router-view></router-view>
</template>

<script>
import AtendimentosService from './services';
import { atendimentoStatusLista } from './AtendimentoStatus';
import BtnDetalhar from './BtnDetalhar';

export default {
    components: {
        BtnDetalhar,
    },

    data() {
        return {
            atendimentos: [],
            dataAtendimentoAte: null,
            dataAtendimentoDe: null,
            status: 7,
            statusLista: [...atendimentoStatusLista],
        };
    },

    methods: {
        obterAtendimentos() {
            this.$store.dispatch('addRequest');
            let filtroDto = {
                status: this.status,
                dataAtendimentoDe: this.$moment(this.dataAtendimentoDe).format('YYYY-MM-DD'),
                dataAtendimentoAte: this.$moment(this.dataAtendimentoAte).format('YYYY-MM-DD'),
            };
            AtendimentosService.obterTodos(filtroDto).then((response) => {
                if (response?.success) {
                    this.atendimentos = response.data;
                } else {
                    this.atendimentos = null;
                }
                this.$store.dispatch('removeRequest');
            });
        },
    },
    created() {
        this.dataAtendimentoAte = new Date();
        this.dataAtendimentoDe = new Date(this.$moment(this.dataAtendimentoAte).subtract(1, 'week'));
        this.obterAtendimentos();
    },

    mounted() {},

    computed: {
        mostrarListagem() {
            return this.$route.name === 'NAN_Atendimentos';
        },

        yearRange() {
            let anoFinal = new Date().getFullYear();
            let anoInicial = anoFinal - 120;
            return `${anoInicial}:${anoFinal}`;
        },
    },
};
</script>
